import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../components/Dashboard/DashboardWrapper';
import Layout from '../components/layout';
import {
  withAuthorization,
} from '../components/Session';
import HeadData from "../data/HeadData"
import BackButton from '../components/Common/BackButton';
import SymposiumVideosSectionNewVersion from '../components/Sections/SymposiumVideosSectionNewVersion';

const SRASymposiumsPageBase = () => (
  <DashboardWrapper pageName='SRA Symposiums On Demand'>
    <div className="relative pt-12 pb-32 md:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        
        <SymposiumVideosSectionNewVersion />

        <BackButton buttonLink='/dashboard' />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const SRASymposiumsPage = compose(
  withAuthorization(condition),
)(SRASymposiumsPageBase);

const SRASymposiums = () => (
  <Layout>
    <HeadData title='SRA Symposiums On Demand' />
    <SRASymposiumsPage />
  </Layout>
)

export default SRASymposiums
