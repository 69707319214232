import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import styled from 'styled-components'
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import PortalVideoUpdateNew from '../Common/PortalVideoUpdateNew';

const StyledDiv = styled.div`
  .react-tabs__tab-list {
    margin: 0px;
    border-bottom: 1px solid #b02929;
  }
  .react-tabs__tab {
    font-weight: bold;
    background: #403E42;
    color: #fff;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 8px;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #b02929;
  }
  .react-tabs__tab--selected,
  .react-tabs__tab:hover {
    background: #b02929;
    color: #fff;
  }
  .react-tabs__tab-panel.main-panel {
    padding: 24px 0;
    background: transparent;
  }
`

const SymposiumVideosSectionNewVersion = () => {
  const data = useStaticQuery(graphql`
    query SymposiumVideosSectionNewVersionQuery {
      prismicSraSymposiums {
        data {
          section_title_3
          section_description_3 {
            richText
          }          
        }
      }
      allPrismicSymposiumsSection(sort: { fields: data___symposium_year, order: DESC }) {
        edges {
          node {
            data {
              symposium_year
              section_title
              videos {
                video_title
                video_description {
                  richText
                }                
                wistia_video_id
              }
            }
          }
        }
      }
    }
  `)

  const doc = data.prismicSraSymposiums
  const symposiums = data.allPrismicSymposiumsSection.edges

  return (
    <>
      <div className="w-full max-w-screen-lg mt-6 mb-16">
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold'>{doc.data.section_title_3}</h2>
        {doc.data.section_description_3.richText
          ?
          <div className="mt-6">
            <RichText
              render={doc.data.section_description_3.richText}
              htmlSerializer={htmlSerializer}
            />
          </div>
          :
          null
        }
      </div>

      <StyledDiv>
        <Tabs>
          <TabList>
            {symposiums.map(item => {
              return (
                <Tab>{item.node.data.symposium_year}</Tab>
              )
            })}
          </TabList>

          {symposiums.map(item => {
              return (
                <TabPanel className="main-panel">
                  <h2 className="text-1xl lg:text-3xl text-red-900 font-bold mt-6 mb-6">{item.node.data.section_title}</h2>            

                  <div className='w-full grid grid-cols-1 xl:grid-cols-2 gap-6 xl:gap-16 mt-8 xl:mt-12'>
                    {item.node.data.videos.map(item => {
                      return (
                        <>
                         {item.video_description.richText.length === 0
                          ?
                          <PortalVideoUpdateNew key={item.wistia_video_id} videoID={item.wistia_video_id} videoTitle={item.video_title} withoutInfo>
                          </PortalVideoUpdateNew>
                          :
                          <PortalVideoUpdateNew key={item.wistia_video_id} videoID={item.wistia_video_id} videoTitle={item.video_title}>
                            <RichText render={item.video_description.richText} htmlSerializer={htmlSerializer} />
                          </PortalVideoUpdateNew>
                          }
                        </>
                      )
                    })}
                  </div>
                </TabPanel>
              )
            })}          
        </Tabs>
      </StyledDiv>
    </>
  )
}

export default SymposiumVideosSectionNewVersion